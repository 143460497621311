import React, { useState } from 'react'

import useScreenOrientation from 'react-hook-screen-orientation'
import { Fab, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Layout from '../Layout'
import makeColors from '../lib/makeColors'

const styles = (theme) => ({
  emailIcon: {
    marginRight: theme.spacing(0.5),
    marginLeft: -theme.spacing(1),
  },
  faIcon: {
    color: theme.palette.text.primary,
  },
})

const enhance = withStyles(styles)

export default () => {
  const [colors, setColors] = useState(makeColors())

  let orientation

  try {
    orientation = useScreenOrientation()
  } catch (e) {
    orientation = 'portrait'
  }

  const direction = (orientation.indexOf('portrait') === 0) ? 'column' : 'row'

  const [remember, setRemember] = useState('portrait')
  if (remember !== orientation) {
    setRemember(orientation)
    setColors(makeColors())
  }

  return (
    <Layout colors={colors}>
      <Grid container spacing={2} justify="flex-start" alignItems="flex-start" direction={direction}>
        <Grid item>
          <Header setColors={setColors}/>
        </Grid>
        <Grid item>
          <ContentLink icon='fab fa-linkedin fa-2x' href="https://www.linkedin.com/in/akabos/"/>
        </Grid>
        <Grid item>
          <ContentLink icon='fab fa-gitlab fa-2x' href="https://gitlab.com/akabos"/>
        </Grid>
        <Grid item>
          <ContentLink icon='fab fa-github fa-2x' href="https://github.com/akabos"/>
        </Grid>
        <Grid item>
          <ContentLink icon='fab fa-bitbucket fa-2x' href="https://bitbucket.org/akabos"/>
        </Grid>
        <Grid item>
          <ContentLink icon='fab fa-keybase fa-2x' href="https://keybase.io/akabos"/>
        </Grid>
      </Grid>
    </Layout>
  )
}

const Header = enhance(({ classes, setColors }) => (
  <ContentLink variant="extended" size="large" icon={`fas fa-at fa-2x ${classes.emailIcon}`} href="" onClick={() => {
    setColors(makeColors())
  }}>
    <Typography display="inline" color="textPrimary" variant="h2">akabos</Typography>
  </ContentLink>
))

const ContentLink = enhance(({ variant = 'round', size = 'medium', href, icon, classes, onClick, children }) => (
  <Fab variant={variant} href={href} size={size} disableRipple={true} color="secondary" onClick={onClick}>
    <span className={`${icon} ${classes.faIcon}`}/>
    {children}
  </Fab>
))
