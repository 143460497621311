import { createMuiTheme } from '@material-ui/core'

export default (colors) => createMuiTheme({
  palette: {
    primary: {
      main: colors[0],
    },
    secondary: {
      main: colors[1],
    },
    text: {
      primary: colors[2],
      secondary: colors[3],
    },
    background: {
      default: colors[1],
    },
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: '3rem',
        fontFamily: '"Nunito", sans-serif',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: '2rem',
        fontFamily: '"Nunito", sans-serif',
        fontWeight: 'bold',
        textTransform: 'none',
      },
      h3: {
        fontSize: '1.64rem',
        fontFamily: '"Nunito", sans-serif',
      },
      h4: {
        fontSize: '1.5rem',
        fontFamily: '"Nunito", sans-serif',
      },
      h5: {
        fontSize: '1.285rem',
        fontFamily: '"Nunito", sans-serif',
      },
      h6: {
        fontSize: '1.142rem',
        fontFamily: '"Nunito", sans-serif',
      },
    },
  },
})