export default (colors) => (
  {
    'particles': {
      'number': {
        'value': 200,
        'density': {
          'enable': true,
          'value_area': 1000,
        },
      },
      'color': {
        'value': colors[2],
      },
      'opacity': {
        'value': 0.2,
        'random': false,
        'anim': {
          'enable': false,
          'speed': 1,
          'opacity_min': 0.1,
          'sync': true,
        },
      },
      'line_linked': {
        'enable': true,
        'distance': 150,
        'color': colors[2],
        'opacity': 1,
        'width': 0.2,
      },
      'move': {
        'enable': true,
        'speed': 1,
        'direction': 'right',
        'random': true,
        'straight': true,
        'out_mode': 'out',
        'bounce': true,
      },
    },
    'interactivity': {
      'detect_on': 'window',
      'events': {
        'onhover': {
          'enable': true,
          'mode': 'grab',
        },
        'onclick': {
          'enable': true,
          'mode': 'push',
        },
        'resize': true,
      },
      'modes': {
        'grab': {
          'distance': 150,
          'line_linked': {
            'opacity': 1,
          },
        },
        'push': {
          'particles_nb': 2,
        },
      },
    },
    'retina_detect': true,
  }
)