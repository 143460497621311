import React, { useState } from 'react'
import ReactParticlesJS from 'react-particles-js'
import { Box } from '@material-ui/core'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'

import 'typeface-nunito'

import makeParticles from './lib/makeParticles'
import makeMuiTheme from './lib/makeMuiTheme'

import './Layout.css'

const styles = (theme) => ({
  outer: {
    backgroundColor: theme.palette.background.default,
    height: 100 + 'vh',
    width: 100 + 'vw',
    overflow: 'hidden',
  },
  inner: {
    padding: theme.spacing(4),
    overflow: 'hidden',
  },
  particles: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
})

const enhance = withStyles(styles)

export default enhance(({ title, colors, children }) => {
  const [loading, setLoading] = useState(true)

  if (loading) {
    setTimeout(() => {
      setLoading(false)
    }, 1)
  }

  return <>
    <MuiThemeProvider theme={makeMuiTheme(colors)}>
      <Helmet>
        <title>{title || 'www.akabos.io'}</title>
        <link href="https://use.fontawesome.com/releases/v5.9.0/css/all.css" rel="stylesheet"/>
      </Helmet>
      <Content colors={colors} loading={false}>{children}</Content>
    </MuiThemeProvider>
  </>
})

const Particles = enhance(({ classes, colors }) => {
  return <ReactParticlesJS params={makeParticles(colors)} className={classes.particles}/>
})

const Content = enhance(({ classes, colors, loading, children }) => (
  <Box className={classes.outer}>
    {loading ? null : <Particles colors={colors}/>}
    <Box className={classes.inner}>
      {children}
    </Box>
  </Box>
))
